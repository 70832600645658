<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- Content -->
    <b-card v-if="itemData" no-body>
      <b-badge
        variant="danger"
        class="p-1"
        style="font-size: 15px"
        v-if="this.itemData.deleted_at != null"
      >
        Archived Data
      </b-badge>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="itemData.cover"
                :alt="`Image of ${itemTrans_name}`"
                class="product-img img-fluid"
                fluid
              />
            </div>
          </b-col>
          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>
              <span>{{ $t("Show_Phone_Guide.Name") }} :</span>
              {{ itemTrans_name }}
            </h4>
            <br />
            <b-card-text class="item-company mb-0" v-if="itemData.brand != null">
              <h4>
                <span>{{ $t("Show_Phone_Guide.Brand") }} :</span>
                <b-link>
                  {{ itemData.brand.translation.name }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Product Brand -->
            <b-card-text class="item-company mb-0" v-if="itemData.category != null">
              <h4>
                <span>{{ $t("Show_Phone_Guide.Category") }} :</span>
                <b-link>
                  {{ itemData.category.translation.name }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4>{{ $t("Show_Phone_Guide.Rate") }} :</h4>
              <ul class="unstyled-list list-inline pl-1">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= average_rate },
                      star <= average_rate ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
            <br />

            <!-- Product Short Description -->
            <h5>{{ $t("Show_Phone_Guide.Short_Description") }} :</h5>
            <b-card-text
              class="ml-3"
              v-for="(item, index) in this.itemData.item_translations"
              :key="index"
              ><p v-html="item.short_description"></p>
            </b-card-text>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card title="المواصفات الرئيسية" v-if="itemData.attributes != null">
      <b-row>
        <b-col cols="6" md="2" class="d-flex justify-content-center my-3">
          <div class="text-center">
            <b-img
              height="50px"
              class=""
              src="@/assets/images/PhoneGuide/storage.png"
              alt="Storage"
            ></b-img>
            <p class="text-dark mt-2 mb-0">التخزين</p>
            <div class="d-flex justify-content-center align-items-center">
              <div
                v-for="(item, index) in itemData.attributes"
                class="mb-1 mt-1 text-center"
                :key="index"
                v-if="item.translation.slug == 'مساحه-التخزين'"
              >
                <p
                  v-for="(val, ind) in item.itemValues"
                  :key="ind"
                  class="badge badge-primary storage-badge text-white mb-0"
                >
                  <span class="h5 text-white">{{
                    val.translation.name.match(/[+-]?\d+(\.\d+)?/g)[0]
                  }}</span>
                </p>
              </div>
            </div>
            <p class="m-0">جيجا بايت</p>
          </div>
        </b-col>
        <b-col cols="6" md="2" class="border-right-gray my-3">
          <div class="text-center">
            <b-img
              height="50px"
              class=""
              src="@/assets/images/PhoneGuide/screen.png"
              alt="Screen"
            ></b-img>
            <p class="text-dark mt-2 mb-0">الشاشة</p>
            <div
              v-for="(item, index) in itemData.attributes"
              class="mb-1 mt-1 text-center"
              :key="index"
              v-if="item.translation.slug == 'حجم-الشاشه'"
            >
              <p
                v-for="(val, ind) in item.itemValues"
                :key="ind"
                class="badge badge-primary storage-badge text-white mb-0"
              >
                <span class="h5 text-white">{{
                  val.translation.name.match(/[+-]?\d+(\.\d+)?/g)[0]
                }}</span>
              </p>
            </div>
            <p class="m-0">بوصة</p>
          </div>
        </b-col>
        <b-col cols="6" md="2" class="border-right-gray my-3">
          <div class="text-center">
            <b-img
              height="50px"
              class=""
              src="@/assets/images/PhoneGuide/camera.png"
              alt="Camera"
            ></b-img>
            <p class="text-dark mt-2 mb-0">كاميرا</p>
            <div
              v-for="(item, index) in itemData.attributes"
              class="mb-1 mt-1 text-center"
              :key="index"
              v-if="item.translation.slug == 'كاميرا'"
            >
              <p
                v-for="(val, ind) in item.itemValues"
                :key="ind"
                class="badge badge-primary storage-badge text-white mb-0"
              >
                <span class="h5 text-white">{{
                  val.translation.name.match(/[+-]?\d+(\.\d+)?/g)[0]
                }}</span>
              </p>
            </div>
            <p class="m-0">ميجا بيكسل</p>
          </div>
        </b-col>

        <b-col cols="6" md="2" class="my-3">
          <div class="text-center">
            <b-img
              height="50px"
              class=""
              src="@/assets/images/PhoneGuide/ram.png"
              alt="Ram"
            ></b-img>
            <p class="text-dark mt-2 mb-0">الرام</p>
            <div
              v-for="(item, index) in itemData.attributes"
              class="mb-1 mt-1 text-center"
              :key="index"
              v-if="item.translation.slug == 'الرام'"
            >
              <p
                v-for="(val, ind) in item.itemValues"
                :key="ind"
                class="badge badge-primary storage-badge text-white mb-0"
              >
                <span class="h5 text-white">{{
                  val.translation.name.match(/[+-]?\d+(\.\d+)?/g)[0]
                }}</span>
              </p>
            </div>
            <p class="m-0">جيجا بايت</p>
          </div>
        </b-col>
        <b-col cols="6" md="2" class="my-3">
          <div class="text-center">
            <b-img
              height="50px"
              class=""
              src="@/assets/images/PhoneGuide/wieght.png"
              alt="Ram"
            ></b-img>
            <p class="text-dark mt-2 mb-0">الوزن</p>
            <div
              v-for="(item, index) in itemData.attributes"
              class="mb-1 mt-1 text-center"
              :key="index"
              v-if="item.translation.slug == 'الوزن'"
            >
              <p
                v-for="(val, ind) in item.itemValues"
                :key="ind"
                class="badge badge-primary storage-badge text-white mb-0"
              >
                <span class="h5 text-white">
                  {{ val.translation.name.match(/[+-]?\d+(\.\d+)?/g)[0] }}</span
                >
              </p>
            </div>
            <p class="m-0">جرام</p>
          </div>
        </b-col>
        <b-col cols="6" md="2" class="border-right-gray my-3">
          <div class="text-center">
            <b-img
              height="50px"
              class=""
              src="@/assets/images/PhoneGuide/battery.png"
              alt="Battery"
            ></b-img>
            <p class="text-dark mt-2 mb-0">البطارية</p>
            <div
              v-for="(item, index) in itemData.attributes"
              class="mb-1 mt-1 text-center"
              :key="index"
              v-if="item.translation.slug == 'قدرة-البطارية'"
            >
              <p
                v-for="(val, ind) in item.itemValues"
                :key="ind"
                class="badge badge-primary storage-badge text-white mb-0"
              >
                <span class="h5 text-white">{{
                  val.translation.name.match(/[+-]?\d+(\.\d+)?/g)[0]
                }}</span>
              </p>
            </div>
            <p class="m-0">مللى أمبير</p>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- tags section -->
    <b-card :title="$t('Show_Phone_Guide.Tags')">
      <div
        v-for="(tag, index) in itemData.tags"
        :key="index"
        class="d-inline p-2"
      >
        <span style="font-size: 18px">
          <b-badge variant="primary" class="p-1 badge-glow badge-lg">
            {{ tag.name }}
          </b-badge>
        </span>
      </div>
    </b-card>
    <!-- prices section -->
    <b-card :title="$t('Show_Phone_Guide.Prices')">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="mb-1 mb-sm-0">
          <!-- <span class="text-muted">Commercial networks and enterprises</span> -->
        </div>
        <div class="d-flex align-content-center mb-1 mb-sm-0">
          <h1 class="font-weight-bolder">
            {{ lastPrice }}
          </h1>
          <div class="pt-25 ml-75">
            <b-badge variant="light-secondary">
              <feather-icon
                v-if="priceArrowDown"
                icon="ArrowDownIcon"
                size="12"
                class="text-danger align-middle"
              />
              <feather-icon
                v-if="priceArrowUp"
                icon="ArrowUpIcon"
                size="12"
                class="text-success align-middle"
              />
              <span class="font-weight-bolder align-middle">
                {{ pricePerc }}%
              </span>
            </b-badge>
          </div>
        </div>
      </div>
      <!-- prices echart -->
      <app-echart-line :option-data="option" />
      <!-- prices table -->
      <b-table
        :sticky-header="stickyHeader"
        :no-border-collapse="noCollapse"
        responsive
        :items="itemData.item_prices"
        :fields="fields"
      >
        <template #cell(description)="data">
          <p v-html="data.value"></p>
        </template>
      </b-table>
    </b-card>
    <!-- translations section -->
    <b-card :title="$t('Show_Phone_Guide.Descriptions')">
      <b-tabs align="center" class="text-center">
        <b-tab
          v-for="(translation, index) in this.itemData.item_translations"
          :key="index"
          :title="translation.locale == 'en' ? 'English' : 'Arabic'"
        >
          <b-card-text>
            <div v-html="translation.description"></div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>

    <!-- photo gallery section -->
    <b-card :title="$t('Show_Phone_Guide.Photo_Gallery')">
      <CoolLightBox
        :items="imagesArr"
        :index="imgIndex"
        @close="imgIndex = null"
      ></CoolLightBox>
      <swiper
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide v-for="(data, index) in photoGallery" :key="index">
          <b-img
            width="150"
            height="auto"
            :src="data.src"
            @click="imgIndex = index"
          />
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination" />
      </swiper>
    </b-card>
    <!-- taxonomies section -->
    <b-card>
      <h2>{{ $t("Show_Phone_Guide.Specification") }}</h2>
      <b-tabs>
        <b-tab title="Arabic">
          <!-- <table
              v-for="(item, index) in this.itemData.taxonomies.ar"
              :key="index + 'terms'"
            ></table> -->
          <b-row v-if="this.itemData.taxonomies != null">
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ١</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-1'
                    ]"
                    :key="index + 'terms' + 'المجموعه-1'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ٢</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-2'
                    ]"
                    :key="index + 'terms' + 'المجموعه-2'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ٣</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-3'
                    ]"
                    :key="index + 'terms' + 'المجموعه-3'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ٤</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-4'
                    ]"
                    :key="index + 'terms' + 'المجموعه-4'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ٥</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-5'
                    ]"
                    :key="index + 'terms' + 'المجموعه-5'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ٦</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-6'
                    ]"
                    :key="index + 'terms' + 'المجموعه-6'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ٧</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-7'
                    ]"
                    :key="index + 'terms' + 'المجموعه-7'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ٨</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-8'
                    ]"
                    :key="index + 'terms' + 'المجموعه-8'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ٩</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-9'
                    ]"
                    :key="index + 'terms' + 'المجموعه-9'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ١٠</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-10'
                    ]"
                    :key="index + 'terms' + 'المجموعه-10'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ١١</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-11'
                    ]"
                    :key="index + 'terms' + 'المجموعه-11'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ١٢</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-12'
                    ]"
                    :key="index + 'terms' + 'المجموعه-12'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12" md="4">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">المجموعه ١٣</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'المجموعه-13'
                    ]"
                    :key="index + 'terms' + 'المجموعه-13'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" colspan="2">مميزات اضافية</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.itemData.taxonomies['ar'][
                      'مميزات-اضافية'
                    ]"
                    :key="index + 'terms' + 'مميزات-اضافية'"
                  >
                    <th scope="row">{{ item.pivot.name }}</th>
                    <td>{{ item.pivot.description }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <!-- <b-table-simple
            dir="rtl"
            style="text-align: right;"
            hover
            small
            responsive
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th>
                  {{ $t("Show_Phone_Guide.Specify") }}
                </b-th>
                <b-th>
                  {{ $t("Show_Phone_Guide.Descriptions") }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in taxonomiesAR" :key="index">
              <b-tr>
                <b-th :rowspan="item.length">
                  {{ item[0].name }}
                </b-th>
                <div v-for="(itme, ind) in item" :key="ind">
                  <b-th class="text-right float-left">
                    {{ itme.pivot.name }}
                  </b-th>
                  <b-td class="text-right float-left">
                    {{ itme.pivot.description }}
                  </b-td>
                  <div>
                    <div class="text-right">
                      <b-button
                        class="btn-icon d-hidden mr-0"
                        variant="flat"
                        disabled
                        style="visibility: hidden;"
                      >
                        <feather-icon icon="AlignJustifyIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td colspan="12" variant="secondary" class="text-right">
                  {{ $t("Show_Phone_Guide.Total_Rows") }} :
                  <b>{{ taxonomiesAR.length }}</b>
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple> -->
        </b-tab>
        <b-tab title="English">
          <b-table-simple hover small responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th>
                  {{ $t("Show_Phone_Guide.Specify") }}
                </b-th>
                <b-th>
                  {{ $t("Show_Phone_Guide.Descriptions") }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, tex) in taxonomiesEN" :key="tex">
              <b-tr>
                <b-th :rowspan="item.length">
                  {{ item[0].name }}
                </b-th>
                <div v-for="(itme, ind) in item" :key="ind">
                  <b-th class="text-right float-left">
                    {{ itme.pivot.name }}
                  </b-th>
                  <b-td class="text-right float-left">
                    {{ itme.pivot.description }}
                  </b-td>
                  <div>
                    <div class="text-right">
                      <b-button
                        class="btn-icon d-hidden mr-0"
                        variant="flat"
                        disabled
                        style="visibility: hidden"
                      >
                        <feather-icon icon="AlignJustifyIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-tr>
              <b-tr></b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td colspan="12" variant="secondary" class="text-right">
                  Total Rows:
                  <b>{{ taxonomiesEN.length }}</b>
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "MyComponent",
  components: {
    // BSV
    AppEchartLine,

    Swiper,
    SwiperSlide,
    CoolLightBox,
  },
  data() {
    return {
      deactive: "0",
      p_ID: 0,
      parentSet: true,
      phonegudieCover: "",
      Pname: "",
      average_rate: "",
      Pdesc: "",
      aRate: "",
      
      imgIndex: null,
      imagesArr: [],
      deletedItem: "0",
      id: 0,
      itemData: {},
      itemComments: [],
      errors_back: [],
      showDismissibleAlert: false,
      lastPrice: null,
      pricePerc: null,
      priceArrowUp: false,
      priceArrowDown: false,
      itemTrans_name: null,
    
      
      
      taxonomiesAR: [],
      taxonomiesEN: [],
      option: {
        xAxisData: [],
        series: [],
      },
      photoGallery: [],
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      stickyHeader: true,
      noCollapse: true,
      fields: [
        {
          key: "id",
          label: this.$t("Table_Head.ID"),
          stickyColumn: true,
          isRowHeader: true,
          variant: "primary",
        },
        {
          key: "price",
          label: this.$t("Table_Head.Price"),
        },
        {
          key: "description",
          label: this.$t("Table_Head.Descriptions"),
        },
        {
          key: "date",
          label: this.$t("Table_Head.Date"),
        },
      ],
      swiperOptions: {
        freeMode: false,

        slidesPerView: "auto",
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.showDismissibleAlert = false;
      axios
        .get("items-guide/" + this.id)
        .then((result) => {
          this.itemData = result.data.data;
    
          
          this.option.xAxisData.length = 0;
          this.option.series.length = 0;
          if (this.itemData.item_prices.length > 0) {
            for (let price in this.itemData.item_prices) {
              this.option.xAxisData.push(this.itemData.item_prices[price].date);
              this.option.series.push(this.itemData.item_prices[price].price);
            }
          }
          if (this.itemData.parent != null) {
            this.p_ID = this.itemData.parent.id;
            this.phonegudieCover = this.itemData.parent.cover;
            if (this.itemData.parent.item_translations.length > 0) {
              this.Pname = this.itemData.parent.item_translations[0].name;
              this.Pdesc =
                this.itemData.parent.item_translations[0].short_description;
            }
            this.aRate = this.itemData.parent.average_rate;
            this.parentSet = true;
          } else {
            this.parentSet = false;
          }
          this.lastPrice =
            this.itemData.item_prices[
              this.itemData.item_prices.length - 1
            ].price;
          if (this.itemData.item_prices.length > 1) {
            this.pricePerc = (
              (this.itemData.item_prices[this.itemData.item_prices.length - 2]
                .price /
                this.itemData.item_prices[this.itemData.item_prices.length - 1]
                  .price /
                this.itemData.item_prices[this.itemData.item_prices.length - 2]
                  .price) *
              100 *
              100
            ).toFixed(2);
            if (Math.sign(this.pricePerc) == 1) {
              this.priceArrowUp = false;
              this.priceArrowDown = true;
            } else if (Math.sign(this.pricePerc) == -1) {
              this.priceArrowUp = true;
              this.priceArrowDown = false;
            } else {
              this.priceArrowUp = false;
              this.priceArrowDown = false;
            }
          }

          this.itemTrans_name = this.itemData.item_translations[0].name;

          this.photoGallery.length = 0;
          this.imagesArr.length = 0;
          if (this.itemData.item_images.length > 0) {
            for (let img in this.itemData.item_images) {
              this.photoGallery.push({
                src: this.itemData.item_images[img].file,
                alt: this.itemData.item_images[img].description,
                id: this.itemData.item_images[img].id,
              });
              this.imagesArr.push(this.itemData.item_images[img].file);
            }
          }
          this.taxonomiesAR.length = 0;
          this.taxonomiesEN.length = 0;
          for (var tax in this.itemData.taxonomies["en"]) {
            this.taxonomiesEN.push(this.itemData.taxonomies["en"][tax]);
          }

          for (var tax in this.itemData.taxonomies["ar"]) {
            this.taxonomiesAR.push(this.itemData.taxonomies["ar"][tax]);
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>

<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
.table-head-specify {
  max-width: 10%;
}
.storage-badge {
  padding: 10px;
  margin: 5px;
}
@media only screen and (max-width: 320px) {
  .swiper-slide {
    max-width: calc(100% / 1.6);
  }
}
@media only screen and (min-width: 321px) and (max-width: 400px) {
  .swiper-slide {
    max-width: calc(100% / 1.6);
  }
}
@media only screen and (min-width: 401px) and (max-width: 500px) {
  .swiper-slide {
    max-width: calc(100% / 1.8);
  }
}
@media only screen and (min-width: 501px) and (max-width: 640px) {
  .swiper-slide {
    max-width: calc(100% / 2.3);
  }
}
@media only screen and (min-width: 641px) and (max-width: 768px) {
  .swiper-slide {
    max-width: calc(100% / 3);
  }
}
@media only screen and (min-width: 769px) and (max-width: 890px) {
  .swiper-slide {
    max-width: calc(100% / 3);
  }
}
@media only screen and (min-width: 891px) and (max-width: 1024px) {
  .swiper-slide {
    max-width: calc(100% / 4);
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .swiper-slide {
    max-width: calc(100% / 5);
  }
}
@media only screen and (min-width: 1441px) {
  .swiper-slide {
    max-width: calc(100% / 5);
  }
}
</style>
